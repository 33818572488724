import stelace from 'hc-core/composables/stelace'
import _ from 'lodash'
import {
  getCurrentUserId,
  createCookieInstance,
  shouldAuthenticateAsOrg,
} from 'hc-core/composables/auth'

const cookieInstance = createCookieInstance()

export async function fetchCurrentUser ({ state, commit, dispatch }, { forceRefresh = false } = {}) {
  const isBrowser = typeof window === 'object'
  if (!isBrowser) return

  const currentUser = state.currentUser || {}
  let naturalUser = state.currentNaturalUser || {}
  const userId = getCurrentUserId()
  if (!forceRefresh && naturalUser.id === userId) return currentUser
  if (!userId || currentUser === {}) {
    await dispatch('auth/logout', undefined, { root: true })
    return null
  }

  naturalUser = await stelace.users.read(userId, { stelaceOrganizationId: null })
  commit({ type: 'auth/setCurrentNaturalUser', user: naturalUser }, { root: true })

  const entities = await dispatch('auth/getUserOrganizations', { forceRefresh: true, userId }, { root: true })
  const organizations = entities.organizations
  commit({ type: 'auth/setOrganizations', organizations }, { root: true })

  // fetch config here instead on relying on rootGetters (race condition)
  const config = await dispatch('common/fetchConfig', { forceRefresh: true }, { root: true })

  const authenticateAsOrg = shouldAuthenticateAsOrg(naturalUser, config)
  if (!authenticateAsOrg) {
    if (!Object.keys(currentUser).length && naturalUser !== {}) commit('auth/setCurrentUser', { user: naturalUser }, { root: true })
    else commit('auth/setCurrentUser', { user: currentUser }, { root: true })
    // await dispatch('auth/getHcPerms', undefined, { root: true })
    return naturalUser
  }

  const sortedOrganizations = _.sortBy(organizations, org => org.createdDate)
  const implantationsOrganizations = _.reject(sortedOrganizations, function (org) { return org.metadata.type !== 'implantation' })
  const firstOrg = implantationsOrganizations[0]

  const selectedOrganizationId = createCookieInstance().getOrganizationIdFromCookie()
  const selectedOrg = selectedOrganizationId
    ? organizations ? organizations.find(org => org.id === selectedOrganizationId)
      : null : null

  if (selectedOrg || firstOrg) {
    let organizationId
    if (selectedOrg) {
      organizationId = selectedOrg.id
    } else {
      organizationId = firstOrg.id
    }

    await dispatch('auth/selectOrganization', { organizationId }, { root: true })
  } else {
    commit({ type: 'auth/setCurrentUser', user: naturalUser }, { root: true })
  }
  return naturalUser
}

export function selectOrganization ({ state, commit }, { organizationId }) {
  const organization = state.organizationsById[organizationId]
  if (!organization) return

  commit({ type: 'auth/setCurrentUser', user: organization }, { root: true })

  if (organization.company) {
    commit({ type: 'auth/setUserCompany', company: organization.company }, { root: true })
  }

  stelace.setOrganizationId(organizationId)
  cookieInstance.setOrganizationIdInCookie(organizationId)
}

export async function checkAvailability (context, { username }) {
  const res = await stelace.users.checkAvailability({ username: username })
  return res.available
}

export async function signup ({ commit, dispatch }, { user, noLogin = false }) {
  if (user.email) {
    _.set(user, 'email', user.email.toLowerCase())
    _.set(user, 'username', user.email)
  }
  if (user.username) user.username = user.username.toLowerCase()
  if (_.get(user, 'firstname', false) && _.get(user, 'lastname', false)) {
    _.set(user, 'displayName', `${user.firstname} ${user.lastname[0]}.`)
  }
  let stlUser = await stelace.users.create(_.omit(user, ['metadata', 'platformData']))

  // add timeout to background operations to be completed on the new user
  await new Promise(resolve => setTimeout(resolve, 2000))
  if (noLogin) return stlUser

  createCookieInstance().handleHappyCookie({ value: await stelace.auth.login({ username: user.username, password: user.password }) })
  await dispatch('auth/fetchCurrentUser', undefined, { root: true })

  // Then send metadata and platformData since permissions on namespaces are here
  if (user.metadata || user.platformData) {
    stlUser = await stelace.users.update(stlUser.id, _.pick(user, ['metadata', 'platformData']))
  }

  await stelace.forward.post('/events', {
    type: 'user_login',
    objectId: stlUser.id,
    emitterId: 'happycab-v3'
  })
  return stlUser
}

export async function getUserOrganizations ({ commit, dispatch, rootGetters, state }, { userId, mutate = true, forceRefresh = true, cache = 10 } = {}) {
  let data = { brand: null, implantations: [], users: [], companies: [], plan: null }
  try {
    const now = new Date().getTime()
    const cacheExpired = state.lastUpdate === null ? true : now > state.lastUpdate + (cache * 1000)
    if (!forceRefresh && state.loading) return
    if (!forceRefresh && !cacheExpired) return
    if (!getCurrentUserId()) {
      await dispatch('auth/logout', undefined, { root: true })
      return
    }
    // commit({ type: types.SET_USER_ORGANIZATIONS_LOADING, value: true })

    userId = userId ?? rootGetters['auth/currentNaturalUser'].id
    const entities = await stelace.forward.get(`/users/elements?id=${userId}`, { fields: ['users', 'companies', 'implantations', 'organizations', 'applications', 'profileAsset'] })

    data = _.merge(data, _.clone(entities))

    for (const implantation of data.implantations) {
      implantation.company = _.get(_.filter(data.companies, (c) => c.ownerId === implantation.id), '[0]', null)
      if (implantation.company) implantation.name = implantation.company.name
      implantation.offers = _.filter(data.offers, (o) => o.ownerId === implantation.id)
      implantation.users = _.filter(data.users, (u) => u.organizations[implantation.id] !== undefined)
    }
    for (const company of data.companies) {
      company.implantation = _.get(_.filter(entities.implantations, (i) => i.id === company.ownerId), '[0]', null)
    }
    if (data.companies[0]) commit({ type: 'setUserCompany', company: data.companies[0] })

    data.plan = rootGetters['auth/currentUser'].subscription
    data.slots = rootGetters['auth/currentUser'].slots

    if (mutate) {
      commit({ type: 'auth/setUserOrganizations', userOrganizations: data }, { root: true })
    }

    return data
  } catch (error) {
    return data
  }
}

export async function changePassword (context, { currentPassword, newPassword }) {
  await stelace.password.change({ currentPassword, newPassword })
}

// ##### Session management #####

export async function login ({ dispatch }, { username, password }) {
  createCookieInstance().handleHappyCookie({ value: await stelace.auth.login({ username: username.toLowerCase(), password }) })
  const user = await dispatch('auth/fetchCurrentUser', undefined, { root: true })
  await stelace.events.create({ type: 'user_login', objectId: user.id, emitterId: 'happycab-v3' })
}

export async function logout ({ commit }, { sessionExpired = false, ssrCookieInstance = null } = {}) {
  try {
    const cInstance = ssrCookieInstance ?? cookieInstance
    if (!sessionExpired) await stelace.auth.logout({ refreshToken: cInstance.getHappyCookie('refreshToken') ?? 'null' })
    stelace.setOrganizationId(null)
    cInstance.logout()
    commit('auth/setCurrentUser', { user: null }, { root: true })
    commit('auth/setCurrentNaturalUser', { user: null }, { root: true })
    commit('auth/setOrganizations', { organizations: [] }, { root: true })
    commit('auth/setUserCompany', { user: null }, { root: true })
  } catch (e) { this.useLogger(e) }
}
